import Script from 'next/script';



export async function getServerSideProps(context) {

  //  const ssr_data_tribe = await getTribeByDomain(context?.req?.headers?.host,1)

    return { 
      pageProps: { 
          //ssr_data_tribe: ssr_data_tribe
      }
  }
}

function App({ Component, pageProps }) {

  return (<><Component 
            {...pageProps} 
            /></>
  )
}

export default App
